import * as React from "react";
import { useEffect } from "react";

const TermBaseExchangePage = () => {
    useEffect(()=>{
      if (typeof window !== 'undefined') {
        window.location = 'https://blog.andovar.com/term-base-exchange-tbx';
      }
    },[])
  return <></>;
};
export default TermBaseExchangePage;
